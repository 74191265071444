
import { sendImageMessage, sendTextMessage } from "@/api/message";
import { updateMessageEditorInfo } from "@/api/message_editor";
import { uploadImage } from "@/api/storage";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSMessageItem from "@/components/student/MSMessageItem.vue";
import MMessageTemplateSheet from "@/components/MMessageTemplateSheet.vue";
import MSpeechBubble from "@/components/MSpeechBubble.vue";
import MStickerSheet from "@/components/MStickerSheet.vue";
import { Message } from "@/entities/message";
import { MessageEditor } from "@/entities/message_editor";
import store from "@/store";
import firebase from "firebase/app";
import { Options, Vue } from "vue-class-component";
import {
  convertToUnixtimeFromDate,
  getDateOneDaysAgoFromUnixtime
} from "@/utils/date";
import { Student } from "@/entities/student";
import dayjs from "dayjs";
import { saveErrorLog } from "@/api/error";
import { Getter, State } from "@/store/helper";
import MsChatGPTModal from "@/components/student/MsChatGPTModal.vue";
import { SchoolConfig } from "@/entities/school";

@Options({
  components: {
    MButton,
    MIcon,
    MSMessageItem,
    MMessageTemplateSheet,
    MStickerSheet,
    MSpeechBubble,
    MsChatGPTModal
  },
  props: {
    student: Object,
    inRecess: Boolean
  },
  watch: {
    newMessage: function() {
      this.updateNewMessageUpdated();
    }
  }
})
export default class MSMessagePanel extends Vue {
  student?: Student;
  inRecess = false;
  newMessage = "";
  files: File[] = [];
  newMessageUpdated = 0;
  lastEditorUpdated = 0;
  editorList: MessageEditor[] = [];
  showOnMobile = false;
  isDisplayStickerSheet = false;
  isDisplayMessageTemplateSheet = false;
  userAgent = "";
  hoverFlag = false;
  shortcutText = "";
  isOpenChatGPTConfigModal = false;

  @State("messageUserRef", "messagePanel")
  messageUserRef!: firebase.firestore.DocumentReference | null;
  @State("messages", "messagePanel")
  messages!: Message[];
  @State("messageEditors", "messagePanel")
  messageEditors!: MessageEditor[];
  @State("messageLoading", "messagePanel")
  messageLoading!: boolean;
  @State("messageLimit", "messagePanel")
  messageLimit!: number;
  @State("messageFinished", "messagePanel")
  messageFinished!: boolean;
  @State("messageUserSchoolConfig", "messagePanel")
  messageUserSchoolConfig!: SchoolConfig | null;
  @Getter("isAdmin") isAdmin!: boolean;

  get fileUrls(): string[] {
    return this.files.map(file => URL.createObjectURL(file));
  }

  get studentPagePath(): string {
    if (!this.student) {
      return "";
    }

    const classroomRef = this.student.ref.parent.parent;
    if (!classroomRef) {
      return "";
    }
    const schoolRef = classroomRef.parent.parent;
    if (!schoolRef) {
      return "";
    }

    return `/student/${schoolRef.id}/${classroomRef.id}/${this.student.ref.id}/profile`;
  }

  get schoolInformation(): string {
    if (!this.student) {
      return "";
    }
    const classroomRef = this.student.ref.parent.parent!;
    const schoolRef = classroomRef.parent.parent!;
    const matchSchools = store.state.schools.filter(
      school => school.ref.id === schoolRef.id
    );
    const matchClassrooms = store.state.classrooms.filter(
      classroom => classroom.ref.id === classroomRef.id
    );
    if (matchSchools.length > 0) {
      return matchSchools[0].data.name;
    } else if (matchClassrooms.length > 0) {
      return matchClassrooms[0].data.name;
    } else {
      return "スクール情報不明";
    }
  }

  get currentRunningDayCount(): number {
    if (!this.student) {
      return 0;
    }

    const oneDayAgoDate = getDateOneDaysAgoFromUnixtime(
      Math.floor(Date.now() / 1000)
    );
    if (
      this.student.data.latestLearnings.length === 0 ||
      this.student.data.latestLearnings[0].start <
        convertToUnixtimeFromDate(oneDayAgoDate)
    ) {
      return 0;
    }

    return this.student.data.currentRunningDayCount;
  }

  get currentDayCountMessage(): string {
    if (!this.student) {
      return "";
    }
    if (this.currentRunningDayCount > 0) {
      return `${this.currentRunningDayCount}日連続入室中`;
    } else if (this.student.data.latestLearnings.length === 0) {
      return "学習室未利用です";
    } else {
      const day = dayjs(this.student.data.latestLearnings[0].start * 1000);
      const current = dayjs(Date.now());
      const diff = current.diff(day, "day");
      return `${diff}日前に最終入室`;
    }
  }

  get totalDayCount(): number {
    if (!this.student) {
      return 0;
    }
    return this.student.data.totalDayCount;
  }

  get dynamicButtonCss() {
    if (this.newMessage || this.files.length > 0) {
      return {
        "bg-primary-500": true,
        "hover:bg-primary-600": true,
        "cursor-pointer": true
      };
    } else {
      return {
        "bg-gray-300": true,
        "cursor-default": true
      };
    }
  }

  get sliderCss() {
    if (window.innerWidth < 1024 && this.showOnMobile) {
      return {
        "slider-show": true,
        "slider-hide": false
      };
    } else {
      return {
        "slider-show": false,
        "slider-hide": true
      };
    }
  }

  get transformCss() {
    if (window.innerWidth < 1024 && this.showOnMobile) {
      return "";
    } else {
      return "transform rotate-180";
    }
  }

  imageUpload(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    let index = 0;
    while (index < event.target.files.length) {
      if (this.files.length == 3) {
        alert("同時に送信できる画像は3個までです");
        break;
      }
      const item = event.target.files.item(index);
      if (item) {
        if (item.size > 750000) {
          alert("750KB以上のファイルサイズの画像は送信できません");
        } else {
          this.files.push(item);
        }
      }
      index++;
    }
    this.updateNewMessageUpdated();
  }

  removeImage(index: number) {
    const file = this.files[index];
    this.files = this.files.filter(item => item.name !== file.name);
    this.updateNewMessageUpdated();
  }

  getMoreMessages() {
    store.dispatch("messagePanel/getMoreMessage");
  }

  mouseOverAction() {
    this.hoverFlag = true;
  }
  mouseLeaveAction() {
    this.hoverFlag = false;
  }

  async sendMessage() {
    if (
      !this.messageUserRef ||
      !store.state.role ||
      (!this.newMessage && this.files.length === 0)
    ) {
      return;
    }
    if (
      /*
        通常ありえないが、選択中の生徒のものでないメッセージが混じっている場合
        中断する
      */
      this.messages.some(
        m => m.ref.parent.parent!.id !== this.messageUserRef?.id
      )
    ) {
      alert(
        "選択した生徒と異なる生徒とのメッセージが表示されているので、送信ミスを防ぐため中断します。画面を再読み込みして再度試してください。"
      );
      return;
    }

    let fromName: string;
    if (store.state.role.data.type === "tutor" && store.state.tutor) {
      fromName = store.state.tutor.main.data.name;
    } else if (store.state.role.data.type === "admin" && store.state.admin) {
      fromName = store.state.admin.name;
    } else {
      return;
    }

    if (this.newMessage) {
      const messageText = this.newMessage;
      this.newMessage = "";
      try {
        await sendTextMessage(
          this.messageUserRef,
          store.state.school ? store.state.school.data.name : "",
          {
            type: store.state.role.data.type,
            userId: store.state.role.ref.id,
            name: fromName
          },
          messageText
        );
      } catch (e) {
        alert(
          `メッセージの送信に失敗しました。時間を置いてから再度実行してみてください。\n\n${e}`
        );
        if (!this.newMessage) {
          this.newMessage = messageText;
        }
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to send message"
        );
        return;
      }
    }

    store.commit("START_LOADING", "画像送信中...");

    try {
      const imagePathes = await Promise.all(
        this.files.map(file => uploadImage(file, this.messageUserRef!.id))
      );
      await Promise.all(
        imagePathes.map(path =>
          sendImageMessage(
            this.messageUserRef!,
            {
              type: store.state.role!.data.type,
              userId: store.state.role!.ref.id,
              name: fromName
            },
            path
          )
        )
      );
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`画像メッセージの送信に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to send image message"
      );
      return;
    }

    this.files = [];
  }

  addMessage(content: string) {
    this.newMessage += content;
    this.isDisplayMessageTemplateSheet = false;
  }

  openAddMessageTemplateModal() {
    store.commit(
      "messageTemplateSheet/SET_DEFAULT_MESSAGE_CONTENT",
      this.newMessage
    );
    store.commit(
      "messageTemplateSheet/SET_IS_OPEN_ADD_MESSAGE_TEMPLATE_MODAL",
      true
    );
  }

  async sendStickerImage(imageUrl: string) {
    if (!this.messageUserRef || !store.state.role) {
      return;
    }

    let fromName: string;
    if (store.state.role.data.type === "tutor" && store.state.tutor) {
      fromName = store.state.tutor.main.data.name;
    } else if (store.state.role.data.type === "admin" && store.state.admin) {
      fromName = store.state.admin.name;
    } else {
      return;
    }

    try {
      await sendImageMessage(
        this.messageUserRef!,
        {
          type: store.state.role!.data.type,
          userId: store.state.role!.ref.id,
          name: fromName
        },
        imageUrl
      );
    } catch (e) {
      alert(
        `スタンプの送信に失敗しました。時間を置いてから再度実行してみてください\n\n${e}`
      );
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to send sticker"
      );
      return;
    }
  }

  updateNewMessageUpdated() {
    this.newMessageUpdated = Math.floor(Date.now() / 1000);
  }

  updateMessageEditor() {
    if (!store.state.role || !this.messageUserRef) {
      return;
    }
    const roleType = store.state.role.data.type;
    let user: firebase.firestore.DocumentReference;
    if (roleType === "tutor" && store.state.tutor) {
      user = store.state.tutor.main.ref;
    } else if (roleType === "admin" && store.state.admin) {
      user = store.state.admin.ref;
    } else {
      return;
    }

    if (this.lastEditorUpdated < this.newMessageUpdated) {
      updateMessageEditorInfo(this.messageUserRef, user).catch(e => {
        alert(e);
        saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to update message editor info"
        );
      });
      this.lastEditorUpdated = this.newMessageUpdated;
    }

    this.editorList = this.messageEditors
      .filter(
        editor => editor.data.timestamp >= Math.floor(Date.now() / 1000 - 5)
      )
      .filter(editor => editor.data.user.path !== user.path);
  }

  reflectMessage(message: string) {
    this.newMessage = message;
  }

  created() {
    this.userAgent = window.navigator.userAgent.toLowerCase();
    if (this.userAgent.indexOf("macintosh") !== -1) {
      this.shortcutText = "[control]キー + [⌘]キー + [スペース]キー";
    }
    if (this.userAgent.indexOf("windows") !== -1) {
      this.shortcutText = "[Windows] + [.]キー";
    }
    setInterval(this.updateMessageEditor, 500);
  }

  mounted() {
    if (this.$route.query.isOpenChatGPTConfigModal === "true") {
      this.isOpenChatGPTConfigModal = true;
    }
  }

  openChatGPTConfigModal(flag: boolean) {
    this.isOpenChatGPTConfigModal = flag;
  }
  closeChatGPTConfigModal() {
    this.isOpenChatGPTConfigModal = false;
  }
}
